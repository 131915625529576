import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    actionButton: {
        margin: '5px',
      background: '#008CBA',
      color: 'white',
      '&:hover':{
        background: '#1d3557'
      }
    },
    backButton: {
      margin: '5px',
      background: '#FF6961',
      color: 'white',
      '&:hover':{
        background: '#d62828'
      }
    },
    stepper_label: {
      color: '#008CBA',
     
    },
    file_input: {
      display: 'none',
    },
    page_title: {
      background: "#70C3CD !important", //2196f3
    },
    page_title_ios: {
      background: "#70C3CD !important", //2196f3
      paddingTop: '20px',
    },
    title: {
      flexGrow: 1,
      lineHeight: '50px',
      fontSize: '18px !important'
    },
  card_title: {
    display: 'block',
    marginTop: '0px',
    marginBottom: '10px',
    width: '100%',
    fontSize: '18px !important',
    background: '#70C3CD',
    paddingLeft: "10px",
    paddingRight: "10px",
    color: 'white',
    },
    nopadding:  {
      padding: '0px !important'
    },
    card_header: {
      background: "#70C3CD",
      color: 'white'
    },
    outlined_card: {
        border: '1px solid #dfdfdf',
        margin: '10px',
        padding: '0px !important'
    },
    padded_15: {
        padding: '15px'
    },
    left_align_block: {
        display: 'inline-block',
        textAlign: 'left',
        float: 'left',
      },
      right_align_block: {
        display: 'inline-block',
        textAlign: 'right', 
        float: 'right',
      },
      greyed_title: {
          background: '#dfdfdf'
      },
      survey_header: {
        padding: '3px',
        paddingLeft: "10px",
        paddingRight: "10px",
        background: '#70C3CD',
        height: 'auto',
        display: 'inline-block',
        width: '100%',
        color: 'white',
        lineHeight: '50px'
      },
      delButton: {
        background: '#FF6961',
        color: 'white',
        '&:hover': {
          background: '#ec524b'
        }
      },
      greenButton: {
        background: '#32a852',
        color: 'white',
        '&:hover': {
          background: '#ec524b'
        }
      },
      a_button: {
          color: 'white',
          textDecoration: 'none',
          display: 'inline-block',
          margin: '5px',
          background: '#3880ff',
          '&:hover':{
            background: '#1d3557'
          },
          '& a': {
            textDecoration: 'none',
          },
          padding: '5px',
          fontSize: '14px',
          verticalAlign: 'bottom',
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
          lineHight: '1.75',
          borderRadius: '4px',
          letterSpacing: '0.02857em',
          textTransform: 'uppercase',

      },
      a_link: {
        textDecoration: 'none',
        color: 'white',
      },
      button_label:
       {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        
       },
       fancy_card: {
        color: 'black',
        border: '1px solid black',
        margin: '0.5em',
        width: '100%'
      },
      center_align: {
        textAlign: 'center',
      },
      wide_modal: {
        width: '90%'
      },
      selected_image: {
        border: '2px solid #70C3CD',
      },
      grey_card: {
        background: '#efefef',
        margin: '10px',
        width: '100%',
      },
      simple_white_card: {
        width: '100%',
        border: 'none',
        boxShadow: 'none',
      },
      fullslide: {
        height: '80vh',
      }
  }));

export default useStyles