import React from "react";

import async from "../components/Async";

import { Sliders } from "react-feather";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import dsm_logo from "../assets/dsm/icon.png";
import GrainIcon from "@material-ui/icons/Grain";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTrackedState, useDispatch } from "../system/store";


// Platform components
const PlatformDashboard = async(() => import("../pages/platform/Dashboard"));
const PlatformProfile = async(() => import("../pages/platform/Profile"));
const PlatformUsers = async(() => import("../pages/platform/Users"));
const PlatformProjects = async(() => import("../pages/platform/Projects"));
const PlatformCompany = async(() => import("../pages/platform/Company"));
const PlatformCompanies = async(() => import("../pages/platform/Companies"));
const PlatformGroups = async(() => import("../pages/platform/Groups"));
const PlatformGroup = async(() => import("../pages/platform/Group"));
const PlatformUsageLog = async(() => import("../pages/platform/UsageLog"));

//DSM components

const DSMNewSurvey = async(() => import("../pages/dsm/NewDiaryTab"));
const DSMAdmin = async(() => import("../pages/dsm/DiaryAdminTab"));
const DSMProjects = async(() => import("../pages/dsm/Projects"));

//AGI components

const AGIMainPage = async(() => import("../pages/agi/MainPage"));
const AGIHelpPage = async(() => import("../pages/agi/HelpPage"));
const AGIProject = async(() => import("../pages/agi/Project"));
const AGIDashboard = async(() => import("../pages/agi/Dashboard"));
const AGIExtraction = async(() => import("../pages/agi/Extraction"));
const AGIDictionary = async(() => import("../pages/agi/Dictionary"));

const AGIDownloadExcel = async(() =>
  import("../pages/agi/Elements/DownloadExcel")
);
const AGIDownloadExcelPivot = async(() =>
  import("../pages/agi/Elements/DownloadExcelPivot")
);

const TCProjects = async(() => import("../pages/tc/Projects"));
const TCProject = async(() => import("../pages/tc/Project"));
const TCCoder = async(() => import("../pages/tc/Coder"));
const TCSentiment = async(() => import("../pages/tc/Sentiment"));

const AccessibilityProjects = async(() =>
  import("../pages/accessibility/Projects")
);
const AccessibilityNewProject = async(() =>
  import("../pages/accessibility/Project")
);
const AccessibilityManual = async(() =>
  import("../pages/accessibility/Manual")
);

//MAXDIFF components

const MaxDiffProjects = async(() => import("../pages/mdif/App"));
const MaxDiffPlatformProjects = async(() => import("../pages/mdif/Projects"));
const NewMDproject = async(()=>import("../pages/mdif/Project"))

//Audio components
const AudioProjects = async(() => import("../pages/audio/Projects"));
const AudioNewProject = async(() => import("../pages/audio/Project"));
const AudioManual = async(() => import("../pages/audio/Manual"));


//Ace components
const AceProjects = async(() => import("../pages/ace/Projects"));
const AceNewProject = async(() => import("../pages/ace/Project"));
const AceManual = async(() => import("../pages/ace/Manual"));


//Google Pins
const GPINSProjects = async(() => import("../pages/googlepins/Projects"));
const GPINSNewProject = async(() => import("../pages/googlepins/Project"));
const GPINSManual = async(() => import("../pages/googlepins/Manual"));

//Doc Parser Pins
const DocParserSandbox = async(() => import("../pages/docparser/Sandbox"));
const DocParserProject = async(() => import("../pages/docparser/Project"));
const DocParserProjects = async(() => import("../pages/docparser/Projects"));
const DocParserManuals = async(() => import("../pages/docparser/Manuals"));


//Article components
const ArticleNew = async(() => import("../pages/articles/Article"));
const Articles = async(() => import("../pages/articles/Articles"));
const ArticleTemplate = async(() => import("../pages/articles/ArticleTemplate"));


//Billing
const Billing = async(() => import("../pages/platform/Billing/index"))

const dashboardRoutes = {
  id: "Dashboard",
  path: "/",
  // header: "Platform",
  icon: <DashboardIcon />,
  containsHome: true,
  children: [
    {
      path: "/",
      name: "Dashboard",
      component: PlatformDashboard,
    },
  ],
  component: null,
};

const platformRoutes = {
  id: "Administration",
  path: "/adm",
  // header: "Platform",
  icon: <SupervisorAccountIcon />,
  containsHome: false,
  //tool_id: "administration",
  children: [
    {
      path: "/adm/users",
      name: "Users List",
      component: PlatformUsers,
    },
    {
      path: "/adm/projects",
      name: "Projects",
      component: PlatformProjects,
      visible: false,
    },
    {
      path: "/adm/company",
      name: "Company Page",
      component: PlatformCompany,
    },
    {
      path: "/adm/companies",
      name: "Companies",
      component: PlatformCompanies,
      //visible: false,
    },
    {
      path: "/adm/usergroups",
      name: "User Groups",
      component: PlatformGroups,
    },
    {
      path: "/adm/usergroup/new",
      name: "New User Group",
      component: PlatformGroup,
      visible: false,
    },
    {
      path: "/adm/profile",
      name: "User Profile",
      component: PlatformProfile,
      visible: false,
    },
  ],
  component: null,
};

const dsmRoutes = {
  id: "DSM",
  path: "/bright_dm",
  icon: <NotificationsActiveIcon />,
  //icon: <img src={dsm_logo} />,
  tool_id: "bright_dm",
  children: [
    {
      path: "/bright_dm/manage",
      name: "Manage Surveys",
      component: DSMAdmin,
      visible: false,
    },
    {
      path: "/bright_dm/new",
      name: "New Survey",
      component: DSMNewSurvey,
      visible: false,
    },
    {
      path: "/bright_dm/projects",
      name: "DSM Projects",
      component: DSMProjects,
    },
  ],
  component: null,
};

const agiRoutes = {
  id: "AGI",
  path: "/bright_agi",
  icon: <RemoveRedEyeIcon />,
  tool_id: "bright_agi",
  children: [
    {
      path: "/bright_agi/projects",
      name: "Projects",
      component: AGIMainPage,
    },
    {
      path: "/bright_agi/project",
      name: "Project",
      component: AGIProject,
      // visible: false,
    },
    {
      path: "/bright_agi/dashboard",
      name: "Project Dashboard",
      component: AGIDashboard,
      //visible: false,
      color: "blue",
    },
    {
      path: "/bright_agi/extraction",
      name: "Extraction",
      component: AGIExtraction,
      // visible: false,
      color: "blue",
    },
    {
      path: "/bright_agi/dowload",
      name: "Download Excel",
      component: AGIDownloadExcel,
      color: "blue",
    },
    {
      path: "/bright_agi/dowloadpivot",
      name: "Download Excel Pivot",
      component: AGIDownloadExcelPivot,
      color: "blue",
    },
    {
      path: "/bright_agi/dict",
      name: "Dictionary",
      component: AGIDictionary,
      // visible: false,
    },
    {
      path: "/bright_agi/help",
      name: "Help",
      component: AGIHelpPage,
    },
    
  ],
  component: null,
};

const tcRoutes = {
  id: "TC",
  path: "/bright_tc",
  icon: <TextFieldsIcon />,
  tool_id: "bright_tc",
  children: [
    {
      path: "/bright_tc/projects",
      name: "Projects",
      component: TCProjects,
    },
    {
      path: "/bright_tc/project",
      name: "Project",
      component: TCProject,
    },
    {
      path: "/bright_tc/coder",
      name: "Coder",
      component: TCCoder,
      visible: false,
    },
    {
      path: "/bright_tc/sentiment",
      name: "Sentiment",
      component: TCSentiment,
      visible: false,
    },
  ],
};

const usageRoutes = {
  id: "Usage",
  path: "/usage",
  icon: <AssessmentIcon />,
  children: [
    {
      path: "/usage",
      name: "Usage Log",
      component: PlatformUsageLog,
    },
  ],
};

const accessibilityRoutes = {
  id: "Accessibility",
  path: "/bright_accessibility",
  icon: <AccessibilityNewIcon />,
  tool_id: "bright_accessibility",
  children: [
    {
      path: "/bright_accessibility/projects",
      name: "Accessibility Projects",
      component: AccessibilityProjects,
    },
    {
      path: "/bright_accessibility/new",
      name: "New Project",
      component: AccessibilityNewProject,
      visible: false,
    },
    {
      path: "/bright_accessibility/manual",
      name: "User Manual",
      component: AccessibilityManual,
    },
  ],
  component: null,
};

const audioRoutes = {
  id: "Audio",
  path: "/bright_audio",
  icon: <AudiotrackIcon />,
  tool_id: "bright_audio",
  children: [
    {
      path: "/bright_audio/projects",
      name: "Audio Projects",
      component: AudioProjects,
    },
    {
      path: "/bright_audio/new",
      name: "New Project",
      component: AudioNewProject,
      visible: false,
    },
    {
      path: "/bright_audio/manual",
      name: "User Manual",
      component: AudioManual,
      visible: true,
    },
  ],
  component: null,
};


const aceRoutes = {
  id: "ACE",
  path: "/bright_ace",
  icon: <DeveloperBoardIcon />,
  tool_id: "bright_ace",
  children: [
    {
      path: "/bright_ace/projects",
      name: "ACE Projects",
      component: AceProjects,
    },
    {
      path: "/bright_ace/new",
      name: "New Project",
      component: AceNewProject,
      visible: false,
    },
    {
      path: "/bright_ace/manual",
      name: "User Manual",
      component: AceManual,
      visible: true,
    },
  ],
  component: null,
};

const mdifRoutes = {
  id: "MaxDiff",
  path: "/bright_md",
  icon: <GrainIcon />,
  tool_id: "bright_md",
  children: [
    {
      path: "/bright_md/projects_internal",
      name: "MaxDiff Projects",
      component: MaxDiffProjects,
      visible: false
    },
    {
      path: "/bright_md/projects",
      name: "MaxDiff Platform Projects",
      component: MaxDiffPlatformProjects,
      //visible: false
    },
    {
      path: "/bright_md/project/new",
      name: "New MaxDiff Project",
      component: NewMDproject,
      visible: false
    },
  ],
  component: null,
};


const billingRoutes = {
  id: "Billing",
  path: "/billing",
  icon: <LocalAtmIcon />,
  //tool_id: "billing",
  //tool_id: "administration",
  children: [
    {
      path: "/billing",
      name: "Billing",
      component: Billing,
      //visible: false
    }, 
  ],
  component: null,
};

const articleRoutes = {
  id: "Articles",
  path: "/",
  icon: <LibraryBooksIcon />,
  visible: "superadmin",
  tool_id: "administration",
  //containsHome: true,
  children: [
    {
      path: "/articles/new",
      name: "New/Edit Article",
      component: ArticleNew,
      visible: "superadmin",
    },
    {
      path: "/articles/list",
      name: "All articles",
      component: Articles,
      visible: "superadmin",
    },
    {
      path: "/tos",
      name: "Article",
      component: ArticleTemplate,
      visible: false,
    },
    {
      path: "/privacy-policy",
      name: "Article",
      component: ArticleTemplate,
      visible: false,
    },
    {
      path: "/changelog",
      name: "Article",
      component: ArticleTemplate,
      visible: false,
    },
    {
      path: "/help",
      name: "Article",
      component: ArticleTemplate,
      visible: false,
    },
  ],
  component: null,
};

const googlepinsRoutes = {
  id: "Google Pins",
  path: "/bright_",
  icon: <LocationOnIcon />,
  tool_id: "bright_googlepins",
  children: [
    {
      path: "/bright_googlepins/projects",
      name: "Google Pins Projects",
      component: GPINSProjects,
    },
    {
      path: "/bright_googlepins/new",
      name: "New Google Pins Project",
      component: GPINSNewProject,
      visible: false,
    },
    {
      path: "/bright_googlepins/manual",
      name: "User Manual",
      component: GPINSManual,
      visible: true,
    },
  ],
  component: null,
}


const docparserRoutes = {
  id: "Doc Parser",
  path: "/",
  icon: <DescriptionIcon />,
  //tool_id: "bright_doc_parser",
  children: [
    {
      path: "/bright_doc_parser/new",
      name: "New Project",
      component: DocParserSandbox,
      visible: false,
    },
    {
      path: "/bright_doc_parser/parse",
      name: "Doc Parser",
      component: DocParserProject,
      visible: false,
    },
    {
      path: "/bright_doc_parser/projects",
      name: "Projects",
      component: DocParserProjects,
      visible: true,
    },
    {
      path: "/bright_doc_parser/manuals",
      name: "User Manual",
      component: DocParserManuals,
      visible: true,
    },
  ],
  component: null,
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoutes,
  platformRoutes,
  dsmRoutes,
  agiRoutes,
  tcRoutes,
  accessibilityRoutes,
  audioRoutes,
  aceRoutes,
  googlepinsRoutes,
  docparserRoutes,
  mdifRoutes,
  articleRoutes,
  usageRoutes,
  billingRoutes
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardRoutes,
  platformRoutes,
  dsmRoutes,
  agiRoutes,
  tcRoutes,
  accessibilityRoutes,
  audioRoutes,
  aceRoutes,
  googlepinsRoutes,
  docparserRoutes,
  mdifRoutes,
  articleRoutes,
  usageRoutes,
  billingRoutes
];
