import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Avatar from "@material-ui/core/Avatar";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useTrackedState } from "../../system/store";
import { useParams } from "react-router-dom";

function TestState() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const state = useTrackedState();

  const {
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    error,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  useEffect(() => {
    dispatch({
      type: "AUTH_INFO",
      authInfo: { error, isAuthenticated, isLoading, user },
    });
  }, [error, isAuthenticated, isLoading, user, dispatch]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${process.env.REACT_APP_AUTH}/api/v2/`,
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH}/userinfo`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log(metadataResponse);
        const user_metadata = await metadataResponse.json();

        dispatch({ type: "ACCESS_TOKEN", accessToken: accessToken });
        console.log(user_metadata);
        // dispatch({ type: "USER_EMAIL", userEmail: user_metadata.email });
      } catch (e) {
        console.log(e);
        // dispatch({ type: "USER_EMAIL", error: e.message });
      }
    };

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { company_id } = useParams();
  // console.log(company_id);
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.center}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          State test
        </Typography>
        <div style={{ display: "flex" }}>
          <div>
            Auth
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button
                onClick={() => loginWithRedirect()}
                disabled={state.authInfo.isAuthenticated}
              >
                Login
              </Button>
              <Button
                onClick={() => logout({ returnTo: window.location.origin })}
                disabled={!state.authInfo.isAuthenticated}
              >
                Logout
              </Button>
            </ButtonGroup>
          </div>
          <div>
            User
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button onClick={() => dispatch({ type: "ADD_NEW_USER" })}>
                Add
              </Button>
              <Button onClick={() => dispatch({ type: "GET_USER" })}>
                Get
              </Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            UserGroup
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button
                onClick={() =>
                  dispatch({ type: "ADD_USERGROUP", name: "team2" })
                }
              >
                Add
              </Button>
              <Button
                onClick={() =>
                  dispatch({
                    type: "GET_USERGROUP",
                    id: "6022754c02096e3eaa69fa46",
                  })
                }
              >
                Get
              </Button>
              <Button onClick={() => dispatch()}>Get List</Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            Company
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button
                onClick={() =>
                  dispatch({
                    type: "ADD_COMPANY",
                    name: "BMR RDI 2",
                    offices: [],
                    billing_address: "Some address string here",
                    info: {
                      address: "Some Address string here",
                      name: "Bright RDI",
                      vat: "Some vat info",
                      cnt: "BG",
                      lang: "BG",
                      billing_email: "konstantin.kosev@bright-mr.com",
                    },
                  })
                }
              >
                Add
              </Button>
              <Button
                onClick={() =>
                  dispatch({
                    type: "GET_COMPANY",
                    id: "6022754c02096e3eaa69fa46",
                  })
                }
              >
                Get
              </Button>
              <Button onClick={() => dispatch()}>Get List</Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            Tool
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button onClick={() => dispatch()}>Add</Button>
              <Button onClick={() => dispatch()}>Get</Button>
              <Button onClick={() => dispatch()}>Get List</Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            UsageLog
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button onClick={() => dispatch()}>Add</Button>
              <Button onClick={() => dispatch()}>Get</Button>
              <Button onClick={() => dispatch()}>Get List</Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            APIkey
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button onClick={() => dispatch()}>Add</Button>
              <Button onClick={() => dispatch()}>Get</Button>
              <Button onClick={() => dispatch()}>Change</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
          <div>
            CompanyLog
            <ButtonGroup
              size="small"
              color="primary"
              variant="outlined"
              orientation="vertical"
            >
              <Button onClick={() => dispatch()}>Add</Button>
              <Button onClick={() => dispatch()}>Get</Button>
              <Button onClick={() => dispatch()}>Delete</Button>
            </ButtonGroup>
          </div>
        </div>
        <JSONPretty
          style={{
            maxWidth: 800,
          }}
          id="json-pretty"
          data={state}
        ></JSONPretty>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  center: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default TestState;
