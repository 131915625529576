const base_url = 'https://dsm.bright-mr.com/v2';
//const base_url = 'http://127.0.0.1:5000';

const URLS = {
    post_survey: base_url + '/diary',
    admin: base_url + '/admin',
    append_ids: base_url + '/diaries/append_ids',
    token: base_url + '/token',
    user: base_url + '/user',
    base: base_url,
};

export {URLS}