import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

function CompanyReg() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { id } = useParams();

  if (!isAuthenticated && id) {
    loginWithRedirect({ appState: { companyId: id } });
  }
  return (
    <div>
      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <CircularProgress
          style={{
            marginTop: "50%",
            marginLeft: "50%",
          }}
        />
      )}
    </div>
  );
}

export default CompanyReg;
