import randomcolor from "randomcolor";
import _ from "lodash";
import { act } from "@testing-library/react";
import { initCoder, initSentiment } from "./store";
import { sugestion_loop } from "./api";

export const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_INFO":
      return {
        ...state,
        authInfo: action.authInfo,
      };
    case "ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.page,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_USER_EDIT":
      return {
        ...state,
        userEdit: action.userEdit,
      };
    case "SET_COMPANY":
      return {
        ...state,
        company: action.company,
      };
    case "SET_EDIT_COMPANY":
      return {
        ...state,
        companyEdit: action.company,
      };
    case "SET_COMPANY_LIST":
      return {
        ...state,
        companyList: action.companyList,
      };
    case "SET_USER_EDIT_ORIGIN":
      return {
        ...state,
        userEditOrigin: action.userEditOrigin,
      };
    case "SET_USER_LIST":
      return {
        ...state,
        userList: action.userList,
      };
    case "CHANGE_USER_EDIT":
      return {
        ...state,
        userEdit: { ...state.userEdit, [action.key]: action.val },
      };
    case "SET_USERGROUP":
      return {
        ...state,
        userGroup: action.userGroup,
      };
    case "SET_USERGROUP_EDIT":
      return {
        ...state,
        userGroupEdit: action.userGroupEdit,
      };
    case "CHANGE_USERGROUP_EDIT":
      return {
        ...state,
        userGroupEdit: { ...state.userGroupEdit, [action.key]: action.val },
      };
    case "SET_LIST_USERGROUP":
      return {
        ...state,
        userGroupList: action.list,
      };
    case "SET_TOOLS":
      return {
        ...state,
        tools: action.tools,
      };
    case "SET_AVAILABLE_TOOLS":
      return {
        ...state,
        available_tools: action.tools,
      };
    case "SET_PROJECTS":
      return {
        ...state,
        projectList: action.projectList,
      };
    case "SET_PROJECT":
      return {
        ...state,
        project: action.project,
      };
    case "SET_CLONE_DUP_DETAILS":
      return {
        ...state,
        clonedupDetails: action.clonedupDetails,
      };
    case "SET_CODER_DETAILS":
      return {
        ...state,
        coderDetails: action.coderDetails,
      };
    case "SET_AGI_DETAILS":
      return {
        ...state,
        agiDetails: action.agiDetails,
      };
    case "SET_COMPANY_ID_LOGIN":
      return {
        ...state,
        companyId: action.companyId,
      };
    case "SET_EDIT_PROJECT":
      return {
        ...state,
        projectEdit: action.project,
      };
    case "SET_COMPANYLOG_LIST":
      return {
        ...state,
        logList: action.loglist,
      };
    case "SET_USAGELOG":
      return {
        ...state,
        usageLog: action.usagelog,
      };
    case "SET_ARTICLES":
      return {
        ...state,
        articlesList: action.articles,
      };
    case "SET_PRJ_API_KEY":
      return {
        ...state,
        projectApiKey: action.apiKey,
      };
    case "SET_ARTICLE_EDIT":
      return {
        ...state,
        articleEdit: action.article,
      };
    case "SET_CURRENT_ARTICLE":
      return {
        ...state,
        currentArticle: action.article,
      };
    case "STARTED":
      return {
        ...state,
        pending: true,
        error: null,
      };
    case "FAILED":
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.message,
      };
      /** BILLING */
    case "SET_BILLING_START_DATE":
      return {
        ...state,
        billingStartDate: action.date,
      };
    case "SET_BILLING_END_DATE":
      return {
        ...state,
        billingEndDate: action.date,
      };
    case "SET_BILLING_COMPANY":
        return {
          ...state,
          billingCompany: action.company,
        };
    case "SET_BILLING_PROJECTS_MD":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.questions},
          {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "QUESTIONS", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      return {
        ...state,
        billingProjectsMD: [{columns: cols, data: extended}],
      };
      case "SET_BILLING_PROJECTS_AGI":
        var extended = action.projects.map(a=>{return(
          [
            {value: a.id},
            {value: a.name},
            {value: a.created_on},
            {value: a.total_transactions},
            {value: a.total_cost?a.total_cost:0 + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
          ]
        )})
        var cols = [
          {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
          {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
          {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
          {title: "TRANSACTIONS", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
          {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        ]
        return {
          ...state,
          billingProjectsAGI: [{columns: cols, data: extended}],
        };
    case "SET_BILLING_PROJECTS_TC":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.total_transactions},
          {value: a.total_cost?a.total_cost:0 + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "TRANSACTIONS", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      return {
        ...state,
        billingProjectsTC: [{columns: cols, data: extended}],
      };
    case "SET_BILLING_PROJECTS_Audio":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      return {
        ...state,
        billingProjectsAudio:  [{columns: cols, data: extended}],
      };
      case "SET_BILLING_PROJECTS_ACE":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      console.log([{columns: cols, data: extended}])
      return {
        ...state,
        billingProjectsACE:  [{columns: cols, data: extended}],
      };
      case "SET_BILLING_PROJECTS_GPINS":
        var extended = action.projects.map(a=>{return(
          [
            {value: a.id},
            {value: a.name},
            {value: a.created_on},
            {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
          ]
        )})
        var cols = [
          {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
          {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
          {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
          {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        ]
        return {
          ...state,
          billingProjectsGPins:  [{columns: cols, data: extended}],
        };
    case "SET_BILLING_PROJECTS_Accessibility":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      return {
        ...state,
        billingProjectsAccessibility: [{columns: cols, data: extended}],
      };
    case "SET_BILLING_PROJECTS_DSM":
      var extended = action.projects.map(a=>{return(
        [
          {value: a.id},
          {value: a.name},
          {value: a.created_on},
          {value: a.cost + "€", style: {font: {bold: true, color:{rgb: "FF0000"}}}},
        ]
      )})
      var cols = [
        {title: "ID", width: {wpx: 150}, style: {font: {bold: true}}},//pixels width
        {title: "NAME", width: {wpx: 200}, style: {font: {bold: true}}},//pixels width
        {title: "DATE", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
        {title: "COST", width: {wpx: 80}, style: {font: {bold: true}}},//pixels width
      ]
      return {
        ...state,
        billingProjectsDSM: [{columns: cols, data: extended}],
      };
    case "SET_AGGREGATED_AUDIO":
      return {
        ...state,
        aggregated_audio: action.cost,

      };
    case "SET_AGGREGATED_ACCESSIBILITY":
      return {
        ...state,
        aggregated_accessibility: action.cost,

      };
      case "SET_AGGREGATED_ACE":
        return {
          ...state,
          aggregated_ace: action.cost,
  
        };
    case "SET_AGGREGATED_DSM":
      return {
        ...state,
        aggregated_dsm: action.cost,

      };
    case "SET_AGGREGATED_MDIFF":
      return {
        ...state,
        aggregated_mdiff: action.cost,

      };
    case "SET_AGGREGATED_GPINS":
      return {
        ...state,
        aggregated_gpins: action.cost,

      };
    case "SET_AGGREGATED_TC":
      return {
        ...state,
        aggregated_tc: action.cost,

      };
    case "SET_AGGREGATED_AGI":
      return {
        ...state,
        aggregated_agi: action.cost,

      };

    case "END":
      return {
        ...state,
        pending: false,
        error: null,
      };

    //CODER -->
    case "HOTKEY":
      return {
        ...state,
        coderHotkey: {
          value: action.value,
          trigger: !state.coderHotkey.trigger,
        },
      };
    case "SWAP_CODER_POS":
      return {
        ...state,
        swapCoderPos: !state.swapCoderPos,
      };
    case "TC_INIT":
      return {
        ...state,
        ...initCoder,
        ...initSentiment,
      };
    case "CODER_INIT":
      return {
        ...state,
        codebookData: [],
        codebookIdTextMap: {},
        verbatimData: [],
        verbatimTextIdsMap: {},
      };
    case "SENTIMENT_INIT":
      return {
        ...state,
      };
    case "SET_CODEBOOK_IDS":
      return {
        ...state,
        codebookCoderId: action.codebookCoderId,
        codebookDuplicateId: action.codebookDuplicateId,
        verbatimCoderId: action.verbatimCoderId,
      };
    case "START_CODER":
      return {
        ...state,
        page: "/bright_tc/coder",
      };
    case "START_SENTIMENT":
      return {
        ...state,
        page: "/bright_tc/sentiment",
      };
    case "SET_EXPORT_FILE":
      return {
        ...state,
        exportResp: {
          file: action.file,
          filename: action.filename,
        },
      };
    case "DELETE_CONFIRM":
      return {
        ...state,
        addDeleteDialog: action.dispatch ? action.dispatch : false,
      };
    case "SET_PROJECT_DATA_TAB":
      return { ...state, projectDataTab: action.projectDataTab };
    case "SET_CODEBOOK_TAB":
      return {
        ...state,
        codebookTab: action.codebookTab,
      };
    case "SET_CODEBOOK_TAB_VERBATIM":
      return {
        ...state,
        projectDataTab: "codebook_tab",
        codebookTab: action.codebookTab,
      };
    case "VERBATIM_HISTORY":
      const newVerbatimHistory =
        state.verbatimHistory.length > 5
          ? state.verbatimHistory.slice(1)
          : state.verbatimHistory;
      return {
        ...state,
        verbatimHistory: [...newVerbatimHistory, action.coder_ids],
      };
    case "CLEAR_LAST_HISTORY":
      return {
        ...state,
        verbatimHistory: state.verbatimHistory.slice(0, -1),
      };
    case "VERBATIM_SUG_LOOP":
      return {
        ...state,
        verbatimData: state.verbatimData.map((el) => {
          return {
            ...el,
            sug: sugestion_loop(state.codebookData, el.text_lower),
          };
        }),
      };
    case "EDIT_CODEBOOK_DIALOG":
      return {
        ...state,
        editCodebookrowdialog: action.value,
      };
    case "ADD_VERBATIM_INFO":
      return {
        ...state,
        verbatimInfo: action.verbatimInfo,
      };
    case "SET_VERBATIM_CODES_LIVE":
      const coder_ids = _.keyBy(action.coder_ids, "id");
      console.log(action.coder_ids);
      return {
        ...state,
        verbatimData: state.verbatimData.map((el) =>
          el.id in coder_ids
            ? { ...el, code_id: _.xor(el.coder_id, coder_ids[el.id].code_id) }
            : { ...el }
        ),
      };
    case "ADD_CODEBOOK_INFO":
      return {
        ...state,
        codebookInfo: action.codebookInfo,
      };
    case "ADD_VERBATIM_DATA":
      if (action?.verbatimData?.length) {
        const verbatimTextIdsMap = action.verbatimData.reduce((acc, curr) => {
          if (curr.text_lower in acc) {
            acc[curr.text_lower] = [...acc[curr.text_lower], curr.id];
          } else {
            acc[curr.text_lower] = [curr.id];
          }
          return acc;
        }, {});

        return {
          ...state,
          verbatimData: action.verbatimData,
          verbatimTextIdsMap: verbatimTextIdsMap,
        };
      };
    case "ADD_VERBATIM_DATA_CODER":
      return {
        ...state,
        verbatimData: action.verbatimData,
      };
    case "ADD_VERBATIM_FILTER_COLUMNS":
      return {
        ...state,
        verbatimFilterColumns: action.verbatimFilterColumns,
      };
    case "ADD_CODEBOOK_DATA":
      if (action?.codebookData?.length) {
        const codebookIdTextMap = action.codebookData.reduce((acc, curr) => {
          acc[curr.id] = {
            text: curr.text,
            color:
              curr.id in state.codebookIdTextMap
                ? state.codebookIdTextMap[curr.id].color
                : randomcolor({
                  luminosity: Math.random() < 0.5 ? "light" : "dark",
                }),
            id: curr.id,
            code: curr.code,
          };
          return acc;
        }, {});
        const codebookData = action.codebookData;

        return {
          ...state,
          codebookData: codebookData,
          codebookIdTextMap: codebookIdTextMap,
        };
      }
      return state;
    case "SELECTED_CODEBOOK":
      return { ...state, codebookSelected: action.codebookSelected };
    case "SET_FREQMAP":
      return { ...state, coderfreqMap: action.coderfreqMap };
    case "SELECTED_VERBATIM":
      return { ...state, verbatimSelected: action.verbatimSelected };
    case "ADD_CODEBOOK_DIALOG":
      return { ...state, addCodebookrowdialog: !state.addCodebookrowdialog };
    case "ADD_CODEBOOK_DIALOG_VERB":
      return {
        ...state,
        addCodebookrowdialogVerb: Boolean(action.addCodebookrowdialogVerb)
          ? action.addCodebookrowdialogVerb
          : false,
      };
    case "NEW_CODEBOOK_DIALOG":
      return { ...state, addCodebookNewdialog: !state.addCodebookNewdialog };
    default:
      console.log(action.type);
      throw new Error("unknown action type");
  }
};
