class Notification{
    push_title: string;
    push_message: string;


    survey_status_message: string;
    action: string;

    modal: Boolean;
    image_url: string;
    modal_title: string;
    modal_message: string;

    auto: Boolean;
    cond: string;
    days_array: Array<boolean>;
    time: string;

    inactivity_reminder: Boolean;
    inactivity_days: string;
    inactivity_execution_time: string;
    cnt: string;
    
    
    
    constructor(
            push_title: string = '',
            push_message: string = '',

            survey_status_message: string = '',
            action: string = '',

            modal: Boolean = new Boolean(false),
            image_url: string = '',
            modal_title: string = '',
            modal_message: string = '',

            auto: Boolean = new Boolean(false),
            cond: string = '',
            days_array: Array<boolean> = [...new Array(7)].map(()=>false),
            time: string = '',
            inactivity_reminder: Boolean = new Boolean(false),
            inactivity_days: string = '1',
            inactivity_execution_time: string = '',
            cnt: 'enUS'

        ){
            this.push_title = push_title;
            this.push_message =push_message;


            this.survey_status_message = survey_status_message;
            this.action = action;

            this.modal = modal;
            this.image_url = image_url;
            this.modal_title = modal_title;
            this.modal_message = modal_message;

            this.auto = auto;
            this.cond = cond;
            this.days_array = days_array;
            this.time = time;

            this.inactivity_reminder = inactivity_reminder;
            this.inactivity_days = inactivity_days;
            this.inactivity_execution_time = inactivity_execution_time;
    }

}
  

class DiaryUser{
    id: string;
    diary_id: string;
    diaries: Array<Diary>;
    link: string;
    active: boolean;
    constructor(diary_id: string, id: string, diaries: Array<Diary>, link: string, active: boolean = true){
        this.id = id;
        this.diary_id = diary_id;
        this.diaries = diaries;
        this.link = link;
        this.active = active;
    }
}

class Admin{
    email: string;
    active: Boolean;
    constructor(email: string, active:Boolean){
        this.email=email;
        this.active=active;
    }
}


const countries = [ 
    {label: 'Afghanistan', code: 'AF'}, 
    {label: 'Åland Islands', code: 'AX'}, 
    {label: 'Albania', code: 'AL'}, 
    {label: 'Algeria', code: 'DZ'}, 
    {label: 'American Samoa', code: 'AS'}, 
    {label: 'AndorrA', code: 'AD'}, 
    {label: 'Angola', code: 'AO'}, 
    {label: 'Anguilla', code: 'AI'}, 
    {label: 'Antarctica', code: 'AQ'}, 
    {label: 'Antigua and Barbuda', code: 'AG'}, 
    {label: 'Argentina', code: 'AR'}, 
    {label: 'Armenia', code: 'AM'}, 
    {label: 'Aruba', code: 'AW'}, 
    {label: 'Australia', code: 'AU'}, 
    {label: 'Austria', code: 'AT'}, 
    {label: 'Azerbaijan', code: 'AZ'}, 
    {label: 'Bahamas', code: 'BS'}, 
    {label: 'Bahrain', code: 'BH'}, 
    {label: 'Bangladesh', code: 'BD'}, 
    {label: 'Barbados', code: 'BB'}, 
    {label: 'Belarus', code: 'BY'}, 
    {label: 'Belgium', code: 'BE'}, 
    {label: 'Belize', code: 'BZ'}, 
    {label: 'Benin', code: 'BJ'}, 
    {label: 'Bermuda', code: 'BM'}, 
    {label: 'Bhutan', code: 'BT'}, 
    {label: 'Bolivia', code: 'BO'}, 
    {label: 'Bosnia and Herzegovina', code: 'BA'}, 
    {label: 'Botswana', code: 'BW'}, 
    {label: 'Bouvet Island', code: 'BV'}, 
    {label: 'Brazil', code: 'BR'}, 
    {label: 'British Indian Ocean Territory', code: 'IO'}, 
    {label: 'Brunei Darussalam', code: 'BN'}, 
    {label: 'Bulgaria', code: 'BG'}, 
    {label: 'Burkina Faso', code: 'BF'}, 
    {label: 'Burundi', code: 'BI'}, 
    {label: 'Cambodia', code: 'KH'}, 
    {label: 'Cameroon', code: 'CM'}, 
    {label: 'Canada', code: 'CA'}, 
    {label: 'Cape Verde', code: 'CV'}, 
    {label: 'Cayman Islands', code: 'KY'}, 
    {label: 'Central African Republic', code: 'CF'}, 
    {label: 'Chad', code: 'TD'}, 
    {label: 'Chile', code: 'CL'}, 
    {label: 'China', code: 'CN'}, 
    {label: 'Christmas Island', code: 'CX'}, 
    {label: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {label: 'Colombia', code: 'CO'}, 
    {label: 'Comoros', code: 'KM'}, 
    {label: 'Congo', code: 'CG'}, 
    {label: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {label: 'Cook Islands', code: 'CK'}, 
    {label: 'Costa Rica', code: 'CR'}, 
    {label: 'Cote D\'Ivoire', code: 'CI'}, 
    {label: 'Croatia', code: 'HR'}, 
    {label: 'Cuba', code: 'CU'}, 
    {label: 'Cyprus', code: 'CY'}, 
    {label: 'Czech Republic', code: 'CZ'}, 
    {label: 'Denmark', code: 'DK'}, 
    {label: 'Djibouti', code: 'DJ'}, 
    {label: 'Dominica', code: 'DM'}, 
    {label: 'Dominican Republic', code: 'DO'}, 
    {label: 'Ecuador', code: 'EC'}, 
    {label: 'Egypt', code: 'EG'}, 
    {label: 'El Salvador', code: 'SV'}, 
    {label: 'Equatorial Guinea', code: 'GQ'}, 
    {label: 'Eritrea', code: 'ER'}, 
    {label: 'Estonia', code: 'EE'}, 
    {label: 'Ethiopia', code: 'ET'}, 
    {label: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {label: 'Faroe Islands', code: 'FO'}, 
    {label: 'Fiji', code: 'FJ'}, 
    {label: 'Finland', code: 'FI'}, 
    {label: 'France', code: 'FR'}, 
    {label: 'French Guiana', code: 'GF'}, 
    {label: 'French Polynesia', code: 'PF'}, 
    {label: 'French Southern Territories', code: 'TF'}, 
    {label: 'Gabon', code: 'GA'}, 
    {label: 'Gambia', code: 'GM'}, 
    {label: 'Georgia', code: 'GE'}, 
    {label: 'Germany', code: 'DE'}, 
    {label: 'Ghana', code: 'GH'}, 
    {label: 'Gibraltar', code: 'GI'}, 
    {label: 'Greece', code: 'GR'}, 
    {label: 'Greenland', code: 'GL'}, 
    {label: 'Grenada', code: 'GD'}, 
    {label: 'Guadeloupe', code: 'GP'}, 
    {label: 'Guam', code: 'GU'}, 
    {label: 'Guatemala', code: 'GT'}, 
    {label: 'Guernsey', code: 'GG'}, 
    {label: 'Guinea', code: 'GN'}, 
    {label: 'Guinea-Bissau', code: 'GW'}, 
    {label: 'Guyana', code: 'GY'}, 
    {label: 'Haiti', code: 'HT'}, 
    {label: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {label: 'Holy See (Vatican City State)', code: 'VA'}, 
    {label: 'Honduras', code: 'HN'}, 
    {label: 'Hong Kong', code: 'HK'}, 
    {label: 'Hungary', code: 'HU'}, 
    {label: 'Iceland', code: 'IS'}, 
    {label: 'India', code: 'IN'}, 
    {label: 'Indonesia', code: 'ID'}, 
    {label: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {label: 'Iraq', code: 'IQ'}, 
    {label: 'Ireland', code: 'IE'}, 
    {label: 'Isle of Man', code: 'IM'}, 
    {label: 'Israel', code: 'IL'}, 
    {label: 'Italy', code: 'IT'}, 
    {label: 'Jamaica', code: 'JM'}, 
    {label: 'Japan', code: 'JP'}, 
    {label: 'Jersey', code: 'JE'}, 
    {label: 'Jordan', code: 'JO'}, 
    {label: 'Kazakhstan', code: 'KZ'}, 
    {label: 'Kenya', code: 'KE'}, 
    {label: 'Kiribati', code: 'KI'}, 
    {label: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {label: 'Korea, Republic of', code: 'KR'}, 
    {label: 'Kuwait', code: 'KW'}, 
    {label: 'Kyrgyzstan', code: 'KG'}, 
    {label: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {label: 'Latvia', code: 'LV'}, 
    {label: 'Lebanon', code: 'LB'}, 
    {label: 'Lesotho', code: 'LS'}, 
    {label: 'Liberia', code: 'LR'}, 
    {label: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {label: 'Liechtenstein', code: 'LI'}, 
    {label: 'Lithuania', code: 'LT'}, 
    {label: 'Luxembourg', code: 'LU'}, 
    {label: 'Macao', code: 'MO'}, 
    {label: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {label: 'Madagascar', code: 'MG'}, 
    {label: 'Malawi', code: 'MW'}, 
    {label: 'Malaysia', code: 'MY'}, 
    {label: 'Maldives', code: 'MV'}, 
    {label: 'Mali', code: 'ML'}, 
    {label: 'Malta', code: 'MT'}, 
    {label: 'Marshall Islands', code: 'MH'}, 
    {label: 'Martinique', code: 'MQ'}, 
    {label: 'Mauritania', code: 'MR'}, 
    {label: 'Mauritius', code: 'MU'}, 
    {label: 'Mayotte', code: 'YT'}, 
    {label: 'Mexico', code: 'MX'}, 
    {label: 'Micronesia, Federated States of', code: 'FM'}, 
    {label: 'Moldova, Republic of', code: 'MD'}, 
    {label: 'Monaco', code: 'MC'}, 
    {label: 'Mongolia', code: 'MN'}, 
    {label: 'Montserrat', code: 'MS'}, 
    {label: 'Morocco', code: 'MA'}, 
    {label: 'Mozambique', code: 'MZ'}, 
    {label: 'Myanmar', code: 'MM'}, 
    {label: 'Namibia', code: 'NA'}, 
    {label: 'Nauru', code: 'NR'}, 
    {label: 'Nepal', code: 'NP'}, 
    {label: 'Netherlands', code: 'NL'}, 
    {label: 'Netherlands Antilles', code: 'AN'}, 
    {label: 'New Caledonia', code: 'NC'}, 
    {label: 'New Zealand', code: 'NZ'}, 
    {label: 'Nicaragua', code: 'NI'}, 
    {label: 'Niger', code: 'NE'}, 
    {label: 'Nigeria', code: 'NG'}, 
    {label: 'Niue', code: 'NU'}, 
    {label: 'Norfolk Island', code: 'NF'}, 
    {label: 'Northern Mariana Islands', code: 'MP'}, 
    {label: 'Norway', code: 'NO'}, 
    {label: 'Oman', code: 'OM'}, 
    {label: 'Pakistan', code: 'PK'}, 
    {label: 'Palau', code: 'PW'}, 
    {label: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {label: 'Panama', code: 'PA'}, 
    {label: 'Papua New Guinea', code: 'PG'}, 
    {label: 'Paraguay', code: 'PY'}, 
    {label: 'Peru', code: 'PE'}, 
    {label: 'Philippines', code: 'PH'}, 
    {label: 'Pitcairn', code: 'PN'}, 
    {label: 'Poland', code: 'PL'}, 
    {label: 'Portugal', code: 'PT'}, 
    {label: 'Puerto Rico', code: 'PR'}, 
    {label: 'Qatar', code: 'QA'}, 
    {label: 'Reunion', code: 'RE'}, 
    {label: 'Romania', code: 'RO'}, 
    {label: 'Russian Federation', code: 'RU'}, 
    {label: 'RWANDA', code: 'RW'}, 
    {label: 'Saint Helena', code: 'SH'}, 
    {label: 'Saint Kitts and Nevis', code: 'KN'}, 
    {label: 'Saint Lucia', code: 'LC'}, 
    {label: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {label: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {label: 'Samoa', code: 'WS'}, 
    {label: 'San Marino', code: 'SM'}, 
    {label: 'Sao Tome and Principe', code: 'ST'}, 
    {label: 'Saudi Arabia', code: 'SA'}, 
    {label: 'Senegal', code: 'SN'}, 
    {label: 'Serbia and Montenegro', code: 'CS'}, 
    {label: 'Seychelles', code: 'SC'}, 
    {label: 'Sierra Leone', code: 'SL'}, 
    {label: 'Singapore', code: 'SG'}, 
    {label: 'Slovakia', code: 'SK'}, 
    {label: 'Slovenia', code: 'SI'}, 
    {label: 'Solomon Islands', code: 'SB'}, 
    {label: 'Somalia', code: 'SO'}, 
    {label: 'South Africa', code: 'ZA'}, 
    {label: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {label: 'Spain', code: 'ES'}, 
    {label: 'Sri Lanka', code: 'LK'}, 
    {label: 'Sudan', code: 'SD'}, 
    {label: 'Suriname', code: 'SR'}, 
    {label: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {label: 'Swaziland', code: 'SZ'}, 
    {label: 'Sweden', code: 'SE'}, 
    {label: 'Switzerland', code: 'CH'}, 
    {label: 'Syrian Arab Republic', code: 'SY'}, 
    {label: 'Taiwan, Province of China', code: 'TW'}, 
    {label: 'Tajikistan', code: 'TJ'}, 
    {label: 'Tanzania, United Republic of', code: 'TZ'}, 
    {label: 'Thailand', code: 'TH'}, 
    {label: 'Timor-Leste', code: 'TL'}, 
    {label: 'Togo', code: 'TG'}, 
    {label: 'Tokelau', code: 'TK'}, 
    {label: 'Tonga', code: 'TO'}, 
    {label: 'Trinidad and Tobago', code: 'TT'}, 
    {label: 'Tunisia', code: 'TN'}, 
    {label: 'Turkey', code: 'TR'}, 
    {label: 'Turkmenistan', code: 'TM'}, 
    {label: 'Turks and Caicos Islands', code: 'TC'}, 
    {label: 'Tuvalu', code: 'TV'}, 
    {label: 'Uganda', code: 'UG'}, 
    {label: 'Ukraine', code: 'UA'}, 
    {label: 'United Arab Emirates', code: 'AE'}, 
    {label: 'United Kingdom', code: 'GB'}, 
    {label: 'United States', code: 'US'}, 
    {label: 'United States Minor Outlying Islands', code: 'UM'}, 
    {label: 'Uruguay', code: 'UY'}, 
    {label: 'Uzbekistan', code: 'UZ'}, 
    {label: 'Vanuatu', code: 'VU'}, 
    {label: 'Venezuela', code: 'VE'}, 
    {label: 'Viet Nam', code: 'VN'}, 
    {label: 'Virgin Islands, British', code: 'VG'}, 
    {label: 'Virgin Islands, U.S.', code: 'VI'}, 
    {label: 'Wallis and Futuna', code: 'WF'}, 
    {label: 'Western Sahara', code: 'EH'}, 
    {label: 'Yemen', code: 'YE'}, 
    {label: 'Zambia', code: 'ZM'}, 
    {label: 'Zimbabwe', code: 'ZW'} 
  ];

const languages = [
    { code: "aa", name: "Afar" },
    { code: "ab", name: "Abkhazian" },
    { code: "ae", name: "Avestan" },
    { code: "af", name: "Afrikaans" },
    { code: "ak", name: "Akan" },
    { code: "am", name: "Amharic" },
    { code: "an", name: "Aragonese" },
    { code: "ar", name: "Arabic" },
    { code: "as", name: "Assamese" },
    { code: "av", name: "Avaric" },
    { code: "ay", name: "Aymara" },
    { code: "az", name: "Azerbaijani" },
    { code: "ba", name: "Bashkir" },
    { code: "be", name: "Belarusian" },
    { code: "bg", name: "Bulgarian" },
    { code: "bh", name: "Bihari languages" },
    { code: "bi", name: "Bislama" },
    { code: "bm", name: "Bambara" },
    { code: "bn", name: "Bengali" },
    { code: "bo", name: "Tibetan" },
    { code: "br", name: "Breton" },
    { code: "bs", name: "Bosnian" },
    { code: "ca", name: "Catalan; Valencian" },
    { code: "ce", name: "Chechen" },
    { code: "ch", name: "Chamorro" },
    { code: "co", name: "Corsican" },
    { code: "cr", name: "Cree" },
    { code: "cs", name: "Czech" },
    {
      code: "cu",
      name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
    },
    { code: "cv", name: "Chuvash" },
    { code: "cy", name: "Welsh" },
    { code: "da", name: "Danish" },
    { code: "de", name: "German" },
    { code: "dv", name: "Divehi; Dhivehi; Maldivian" },
    { code: "dz", name: "Dzongkha" },
    { code: "ee", name: "Ewe" },
    { code: "el", name: "Greek, Modern (1453-)" },
    { code: "en", name: "English" },
    { code: "eo", name: "Esperanto" },
    { code: "es", name: "Spanish; Castilian" },
    { code: "et", name: "Estonian" },
    { code: "eu", name: "Basque" },
    { code: "fa", name: "Persian" },
    { code: "ff", name: "Fulah" },
    { code: "fi", name: "Finnish" },
    { code: "fj", name: "Fijian" },
    { code: "fo", name: "Faroese" },
    { code: "fr", name: "French" },
    { code: "fy", name: "Western Frisian" },
    { code: "ga", name: "Irish" },
    { code: "gd", name: "Gaelic; Scomttish Gaelic" },
    { code: "gl", name: "Galician" },
    { code: "gn", name: "Guarani" },
    { code: "gu", name: "Gujarati" },
    { code: "gv", name: "Manx" },
    { code: "ha", name: "Hausa" },
    { code: "he", name: "Hebrew" },
    { code: "hi", name: "Hindi" },
    { code: "ho", name: "Hiri Motu" },
    { code: "hr", name: "Croatian" },
    { code: "ht", name: "Haitian; Haitian Creole" },
    { code: "hu", name: "Hungarian" },
    { code: "hy", name: "Armenian" },
    { code: "hz", name: "Herero" },
    {
      code: "ia",
      name: "Interlingua (International Auxiliary Language Association)"
    },
    { code: "id", name: "Indonesian" },
    { code: "ie", name: "Interlingue; Occidental" },
    { code: "ig", name: "Igbo" },
    { code: "ii", name: "Sichuan Yi; Nuosu" },
    { code: "ik", name: "Inupiaq" },
    { code: "io", name: "Ido" },
    { code: "is", name: "Icelandic" },
    { code: "it", name: "Italian" },
    { code: "iu", name: "Inuktitut" },
    { code: "ja", name: "Japanese" },
    { code: "jv", name: "Javanese" },
    { code: "ka", name: "Georgian" },
    { code: "kg", name: "Kongo" },
    { code: "ki", name: "Kikuyu; Gikuyu" },
    { code: "kj", name: "Kuanyama; Kwanyama" },
    { code: "kk", name: "Kazakh" },
    { code: "kl", name: "Kalaallisut; Greenlandic" },
    { code: "km", name: "Central Khmer" },
    { code: "kn", name: "Kannada" },
    { code: "ko", name: "Korean" },
    { code: "kr", name: "Kanuri" },
    { code: "ks", name: "Kashmiri" },
    { code: "ku", name: "Kurdish" },
    { code: "kv", name: "Komi" },
    { code: "kw", name: "Cornish" },
    { code: "ky", name: "Kirghiz; Kyrgyz" },
    { code: "la", name: "Latin" },
    { code: "lb", name: "Luxembourgish; Letzeburgesch" },
    { code: "lg", name: "Ganda" },
    { code: "li", name: "Limburgan; Limburger; Limburgish" },
    { code: "ln", name: "Lingala" },
    { code: "lo", name: "Lao" },
    { code: "lt", name: "Lithuanian" },
    { code: "lu", name: "Luba-Katanga" },
    { code: "lv", name: "Latvian" },
    { code: "mg", name: "Malagasy" },
    { code: "mh", name: "Marshallese" },
    { code: "mi", name: "Maori" },
    { code: "mk", name: "Macedonian" },
    { code: "ml", name: "Malayalam" },
    { code: "mn", name: "Mongolian" },
    { code: "mr", name: "Marathi" },
    { code: "ms", name: "Malay" },
    { code: "mt", name: "Maltese" },
    { code: "my", name: "Burmese" },
    { code: "na", name: "Nauru" },
    {
      code: "nb",
      name: "Bokmål, Norwegian; Norwegian Bokmål"
    },
    { code: "nd", name: "Ndebele, North; North Ndebele" },
    { code: "ne", name: "Nepali" },
    { code: "ng", name: "Ndonga" },
    { code: "nl", name: "Dutch; Flemish" },
    { code: "nn", name: "Norwegian Nynorsk; Nynorsk, Norwegian" },
    { code: "no", name: "Norwegian" },
    { code: "nr", name: "Ndebele, South; South Ndebele" },
    { code: "nv", name: "Navajo; Navaho" },
    { code: "ny", name: "Chichewa; Chewa; Nyanja" },
    { code: "oc", name: "Occitan (post 1500)" },
    { code: "oj", name: "Ojibwa" },
    { code: "om", name: "Oromo" },
    { code: "or", name: "Oriya" },
    { code: "os", name: "Ossetian; Ossetic" },
    { code: "pa", name: "Panjabi; Punjabi" },
    { code: "pi", name: "Pali" },
    { code: "pl", name: "Polish" },
    { code: "ps", name: "Pushto; Pashto" },
    { code: "pt", name: "Portuguese" },
    { code: "qu", name: "Quechua" },
    { code: "rm", name: "Romansh" },
    { code: "rn", name: "Rundi" },
    { code: "ro", name: "Romanian; Moldavian; Moldovan" },
    { code: "ru", name: "Russian" },
    { code: "rw", name: "Kinyarwanda" },
    { code: "sa", name: "Sanskrit" },
    { code: "sc", name: "Sardinian" },
    { code: "sd", name: "Sindhi" },
    { code: "se", name: "Northern Sami" },
    { code: "sg", name: "Sango" },
    { code: "si", name: "Sinhala; Sinhalese" },
    { code: "sk", name: "Slovak" },
    { code: "sl", name: "Slovenian" },
    { code: "sm", name: "Samoan" },
    { code: "sn", name: "Shona" },
    { code: "so", name: "Somali" },
    { code: "sq", name: "Albanian" },
    { code: "sr", name: "Serbian" },
    { code: "ss", name: "Swati" },
    { code: "st", name: "Sotho, Southern" },
    { code: "su", name: "Sundanese" },
    { code: "sv", name: "Swedish" },
    { code: "sw", name: "Swahili" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "tg", name: "Tajik" },
    { code: "th", name: "Thai" },
    { code: "ti", name: "Tigrinya" },
    { code: "tk", name: "Turkmen" },
    { code: "tl", name: "Tagalog" },
    { code: "tn", name: "Tswana" },
    { code: "to", name: "Tonga (Tonga Islands)" },
    { code: "tr", name: "Turkish" },
    { code: "ts", name: "Tsonga" },
    { code: "tt", name: "Tatar" },
    { code: "tw", name: "Twi" },
    { code: "ty", name: "Tahitian" },
    { code: "ug", name: "Uighur; Uyghur" },
    { code: "uk", name: "Ukrainian" },
    { code: "ur", name: "Urdu" },
    { code: "uz", name: "Uzbek" },
    { code: "ve", name: "Venda" },
    { code: "vi", name: "Vietnamese" },
    { code: "vo", name: "Volapük" },
    { code: "wa", name: "Walloon" },
    { code: "wo", name: "Wolof" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "yo", name: "Yoruba" },
    { code: "za", name: "Zhuang; Chuang" },
    { code: "zh", name: "Chinese" },
    { code: "zu", name: "Zulu" }
  ];

class Cnt{
    name: string;
    languages: Array<String>
    constructor(
        name: string = '',
        languages: Array<String> = [],
        )
    {
        this.name = name;
        this.languages = languages;
    }
}

class CntSpecName{
    name: string;
    cnt: Cnt;
    constructor(
        name: string = '',
        cnt: Cnt = null
    )
    {
        this.name = name;
        this.cnt = cnt;
    }
}

class CntSpecDesc{
    description: string;
    cnt: Cnt;
    constructor(
        description: string = '',
        cnt: Cnt = null
    ){
        this.description = description;
        this.cnt = cnt;
    }
}

class Diary{
    id: string;
    hum_id: string;
    name: string;
    respondent_names: Array<CntSpecName>;
    descriptions: Array<CntSpecDesc>;
    path: string;
    url: string;
    api_key: string;
    messages: Array<Notification>;
    users: Array<DiaryUser>;
    file: any | null;
    active: Boolean;
    countries: Array<Cnt>;
    constructor(
        id: string = '',
        hum_id: string = '',
        name: string = '',
        respondent_names: Array<CntSpecName> = [null],
        descriptions: Array<CntSpecDesc> = [null],
        path: string = '',
        base_url: string = '',
        api_key: string = '',
        messages: Array<Notification> = [],
        users: Array<DiaryUser> = [],
        file: File | null = null,
        active: Boolean = new Boolean(false),
        countries: Array<Cnt> = [],
        )
    {
        this.id = id;
        this.hum_id = hum_id;
        this.name = name;
        this.respondent_names = respondent_names;
        this.descriptions = descriptions;
        this.path = path;
        this.url = base_url;
        this.api_key = api_key;
        this.messages = messages;
        this.users = users;
        this.active = active;
        this.countries = countries;
    }
}

class AppNotification{
    title: string;
    message: string;
    image_url: string;
    constructor(
        title: string = '',
        message: string = '',
        image_url: string =''
    ){
        this.title = title;
        this.message = message;
        this.image_url = image_url;
    }
}

export {Notification, Diary, DiaryUser, Admin, AppNotification, languages, countries, Cnt, CntSpecName, CntSpecDesc}