import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import {
 IonProgressBar,
 IonIcon,
} from '@ionic/react';
import { checkmarkCircle, arrowBackCircle, arrowForwardCircle, addCircle } from 'ionicons/icons';
import Axios from 'axios';

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { Diary } from '../../assets/dsm/DataStructures'

import useStyles from '../../assets/dsm/cssClasses';
import { URLS } from '../../assets/dsm/URLS';
import Header from "../../components/AppBar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";


import {
 Button,
 Checkbox,
 Grid,
 Link,
 Typography,
 TextField,
 FormControlLabel,
 Chip as MuiChip,
 Divider as MuiDivider,
 Breadcrumbs as MuiBreadcrumbs,
 Paper,
 Tab,
 Tabs,
 Box,
 List,
 ListItem,
 ListItemIcon,
 ListItemText,
 Card
} from "@material-ui/core";

import { useTrackedState, useDispatch } from "../../system/store";
import {firebase} from '../../assets/audio/firebase'
import 'firebase/storage';
import axios from "axios";

//...

import CodeBlock from '@tenon-io/tenon-codeblock';
import { Editor } from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.css'
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const drawerWidth = 258;
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ArticleTemplate = ({ children, routes, width, article }) => {

 const {currentArticle, articlesList, user, page} = useTrackedState();
 const [editState, setEditState] = useState();
 const [mobileOpen, setMobileOpen] = useState(false);
 const { isAuthenticated } = useAuth0();
 const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const dispatch = useDispatch();

  const onChangeArticle = (article_key) => {
    const article = articlesList.find(elem => elem.key == article_key);
    console.log(JSON.stringify(article));

    if(article_key === 'help_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/help" })
    }
    if(article_key === 'tos_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/tos" })
    }
    if(article_key === 'privacy_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/privacy-policy" })
    }
    if(article_key === 'changelog_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/changelog" })
    }
    
  }

 useEffect(()=>{
 
  if(currentArticle){
   setEditState(EditorState.createWithContent(convertFromRaw(JSON.parse(currentArticle.text))))
  }
  
 },[currentArticle])

 useEffect(() => {
  history.push(page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [page]);

 useEffect(()=>{
   if(article == 'tos'){
    console.log('tos');
    onChangeArticle('tos_general')
   }
   if(article == 'privacy-policy'){
    console.log('privacy-policy');
    onChangeArticle('privacy_general')
  }
  if(article == 'changelog'){
    console.log('changelog');
    onChangeArticle('changelog_general')
  }
  if(article == 'help'){
    console.log('help');
    onChangeArticle('help_general')
  }
  //alert(props.article);
  //alert(props);
  console.log(article);
 },[article,articlesList])

 return (
  <Root>
    <Drawer>
        {/* <Hidden mdUp implementation="js"> */}
        <Sidebar
          routes={routes}
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onDrawerToggle={handleDrawerToggle}
        />
        {/* </Hidden> */}
        {/* <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden> */}
      </Drawer>

      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />

        <MainContent>
        <Paper style={{maxHeight: 700, overflow: 'auto', minWidth:'90%', width:'90%', margin: "auto"}}>
          <Box>
          <Editor editorState={editState} readOnly={true} toolbarHidden/>
        </Box>
        </Paper>

        {!isAuthenticated ? null : user ? children : <Loader />}

        </MainContent>
        <Footer />
      </AppContent>
   </Root>

 );
};

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${0}px;
    flex-shrink: 0;
  }
`;




export default ArticleTemplate;
