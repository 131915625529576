import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Button, Box, Container, Grid, Typography } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {
  Server as ServerIcon,
  BarChart2 as BarChart2Icon,
  EyeOff as EyeOffIcon,
  Music as MusicIcon,
  Type as TypeIcon,
  Bell as BellIcon,
} from "react-feather";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 32px;
    width: 32px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const Feature = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};

function Features() {
  return (
    <Wrapper py={20}>
      <Container>
        <TypographyOverline variant="body2" gutterBottom>
          Bright Platform Toolset
        </TypographyOverline>
        <Typography variant="h2" component="h3" gutterBottom>
          All the market research tools that you love, now under one roof.
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          <Feature
            Icon={ServerIcon}
            title="AI Gibberish Inspector"
            description="Detect open-end gibberish answers using our state of art AI-powered inspector. 16 languages supported. "
          />
          <Feature
            Icon={BellIcon}
            title="Diary Survey Manager"
            description="Keep your diary survey respondents updated with our mobile notification app. GDPR compliant!"
          />
          <Feature
            Icon={BarChart2Icon}
            title="MaxDiff Manager"
            description="Build MaxDiff designs and easily perform 3 types of MaxDiff analyses, as well as TURF analysis."
          />
          <Feature
            Icon={EyeOffIcon}
            title="ADA Styles"
            description="Build your Forsta &#8482; Surveys following the ADA and WCAG 2.1 accessibility guidelines with our template styles."
          />
          <Feature
            Icon={MusicIcon}
            title="Audio Questions"
            description="Record Forsta &#8482; Surveys respondent answers like they are doing a live interview. Use Transcription to get the text - all automated!"
          />
          <Feature
            Icon={TypeIcon}
            title="Text-Coding"
            description="Get amazed and speed up your process with our lightweight and flexible text-coding tool, and its AI suggestions."
          />
        </Grid>

        <Box mt={4}>
          <Button
              href="https://rdi.bright-research.com/bright-platform"
              variant="contained"
              color="primary"
              size="large"
              target="_blank"
            >
              Learn more and Sign-in to Bright Platform
            </Button>
          
        </Box>
        
      </Container>
    </Wrapper>
  );
}

export default Features;
