import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider, useDispatch } from "./system/store";
import { createMuiTheme } from "@material-ui/core/styles";

import { ThemeProvider } from "@material-ui/styles";
import { blue, red } from "@material-ui/core/colors";

//TODO remove when both providers are merged
import { SharedStateProvider as AGIProvider } from "./pages/agi/store";

function Auth0ProviderSetup() {
  const dispatch = useDispatch();
  const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: red,
    },
    overrides: {
      MuiTableRow: {
        root: {
          "&$selected": {
            backgroundColor: "skyblue",
          },
          "&$selected:hover": {
            backgroundColor: "aliceblue",
          },
        },
      },
    },
  });

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH}
      clientId="pcjs8iInLExjHzB4unA5KfsrARZFAMko"
      redirectUri={process.env.REACT_APP_HOME}
      audience={`https://${process.env.REACT_APP_AUTH}/api/v2/`}
      scope="read:current_user"
      onRedirectCallback={(appState) => {
        dispatch({
          type: "SET_COMPANY_ID_LOGIN",
          companyId: appState["companyId"],
        });
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Auth0Provider>
  );
}

ReactDOM.render(
  <Provider>
    {/* TODO remove when both providers are merged */}
    <AGIProvider>
      <Auth0ProviderSetup />
    </AGIProvider>
  </Provider>,
  document.getElementById("root")
);
