import { useAuth0 } from "@auth0/auth0-react";
import {
  CssBaseline,
  Hidden,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components/macro";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useTrackedState } from "../system/store";
import Loader from "../components/Loader";
import Presentation from "../pages/presentation/Landing";
import ArticleTemplate from  "../pages/articles/ArticleTemplate"


const drawerWidth = 258;

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { isAuthenticated } = useAuth0();

  const history = useHistory();
  const { page, user, currentArticle } = useTrackedState();

  useEffect(() => {
    history.push(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {/* <Hidden mdUp implementation="js"> */}
        <Sidebar
          routes={routes}
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onDrawerToggle={handleDrawerToggle}
        />
        {/* </Hidden> */}
        {/* <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden> */}
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />

        <MainContent>
          {!isAuthenticated && !currentArticle &&
            <Presentation/>
          }
          {!isAuthenticated && currentArticle &&
          
          <Paper style={{maxWidth: "90% !important"}}>
            <br/><br/>
            <ArticleTemplate/>
          </Paper>
            
          }
          {!isAuthenticated ? null : user ? children : <Loader />}
          
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

// width: ${drawerWidth}px;
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${0}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export default withWidth()(Dashboard);
