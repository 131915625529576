import React, { useEffect, useState } from "react";
import {
  Paper as MuiPaper,
  Typography,
  Box,
  Snackbar
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { useDispatch, useTrackedState } from "../system/store";

const Paper = styled(MuiPaper)(spacing);

function PlAlert() {
  //const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { message } = useTrackedState();
  return (
    <>
      {![null, ''].includes(message) &&
        <Snackbar open={true} autoHideDuration={60000} onClose={() => dispatch({ type: "SET_MESSAGE", message: '' })}>

          <Alert severity="success">
            <AlertTitle>System Message</AlertTitle>
            {message}
          </Alert>

        </Snackbar>
      }

    </>
  );
};


export default PlAlert;
