import { useAuth0 } from "@auth0/auth0-react";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components/macro";
import Routes from "./routes/Routes";
import { useDispatch, useTrackedState } from "./system/store";
import createTheme from "./theme";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const dispatch = useDispatch();
  const { companyId, accessToken, user: userBase } = useTrackedState();
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${process.env.REACT_APP_AUTH}/api/v2/`,
          scope: "read:current_user",
        });

        dispatch({ type: "ACCESS_TOKEN", accessToken: accessToken });
        dispatch({ type: "LOGIN" });
      } catch (e) {
        console.log(e);
        dispatch({ type: "FAILED", error: e.message });
      }
    };

    getUserMetadata();
    dispatch({ type: "GET_ARTICLES"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessToken.length) {
      dispatch({ type: "GET_TOOL_LIST" });
      dispatch({ type: "GET_AVAILABLE_TOOL_LIST" });
      dispatch({ type: "GET_PROJECT_LIST" });
      dispatch({ type: "GET_USER_LIST" });
      dispatch({ type: "GET_USAGELOGS" });
      dispatch({ type: "LIST_COMPANY" });
      
      dispatch({ type: "LIST_USERGROUP"});
      if (userBase) {
        dispatch({ type: "GET_COMPANY", id: userBase.company });
        dispatch({ type: "LIST_COMPANYLOG" });
      }
    }
    dispatch({ type: "GET_ARTICLES"});
  }, [accessToken, dispatch, userBase]);

  useEffect(() => {
    if (companyId && user && accessToken) {
      dispatch({
        type: "NEW_USER",
        email: user.email,
        company: companyId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, user, accessToken]);

  return (
    <>
      <Helmet titleTemplate="BMR Platform" defaultTitle="BMR Platform" />
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={createTheme("DEFAULT")}>
          <ThemeProvider theme={createTheme("DEFAULT")}>
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  );
}

export default App;
