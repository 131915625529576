import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import DashboardLayout from "../layouts/Dashboard";
import CompanyReg from "../pages/platform/CompanyReg";
import TestState from "../pages/platform/TestState";
import { dashboardLayoutRoutes } from "./index";
import {Box} from "@material-ui/core";
import ArticleTemplate from  "../pages/articles/ArticleTemplate"

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path, tool_id }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <Box m={tool_id!=='bright_tc'?10:0} pt={tool_id!=='bright_tc'?2:0}>
                    <element.component {...props} />
                  </Box>
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Box m={tool_id!=='bright_tc'?10:0} pt={tool_id!=='bright_tc'?2:0}>
                <Component {...props} />
              </Box>
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes = () => {
  return (
    <Router>
      <Switch>
      
        <Route exact path="/test">
          <TestState />
        </Route>
        <Route path="/new/company/:id">
          <CompanyReg />
        </Route>

        <Route path="/tos">
          <ArticleTemplate article="tos" routes={dashboardLayoutRoutes} children={DashboardLayout}/>
        </Route>
        <Route path="/privacy-policy">
          <ArticleTemplate article="privacy-policy" routes={dashboardLayoutRoutes} children={DashboardLayout}/>
        </Route>
        <Route path="/changelog">
          <ArticleTemplate article="changelog" routes={dashboardLayoutRoutes} children={DashboardLayout}/>
        </Route>
        <Route path="/help">
          <ArticleTemplate article="help" routes={dashboardLayoutRoutes} children={DashboardLayout}/>
        </Route>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {/*<Redirect from="*" to="/" />*/}
      </Switch>
    </Router>
  );
};

export default Routes;
