// import { useEffect } from "react";
import { useReducerAsync } from "use-reducer-async";
import { createContainer } from "react-tracked";
import { asyncActionHandlers } from "./api";
import { reducer } from "./reducer";
// const storageKey = "persistedState_bmr_platform";

export const initCoder = {
  coderDetails: {
    verbatimId: "",
    codebooks: [],
  },
  swapCoderPos: true,
  projectDataTab: "verbatim_tab",
  projectSearchName: "",
  codebookInfo: null,
  codebookTab: false,
  codebookSettings: {},
  codebookCoderId: "",
  codebookDuplicateId: "",
  addCodebookrowdialog: false,
  addCodebookNewdialog: false,
  editCodebookrowdialog: false,
  addCodebookrowdialogVerb: false,
  addDeleteDialog: false,
  codebookData: [],
  codebookIdTextMap: {},
  codebookSelected: [],
  verbatimInfo: null,
  verbatimCoderId: "",
  verbatimSettings: {},
  verbatimData: [],
  verbatimHistory: [],
  verbatimTextIdsMap: {},
  verbatimSelected: [],
  verbatimFilterColumns: {},
  coderHotkey: { value: "", trigger: true },
  coderfreqMap: {},
  exportResp: {
    file: null,
    filename: "",
  },
  clonedupDetails: null,
};


export const initSentiment = {
  sentimentDetails: {
    verbatimId: "",
  },
};


export const initLangs = {
  english: [],
  spanish: [],
  french: [],
  german: [],
  dutch: [],
  russian: [],
  italian: [],
  swedish: [],
  danish: [],
  norwegian: [],
  polish: [],
  romanian: [],
  finnish: [],
  portuguese: [],
  chinese: [],
  japanese: [],
};

export const initAGI = {
  agiDetails: {
    safeWords: [],
    dicts: initLangs,
    ndicts: initLangs,
    fuzzdicts: initLangs,
    dataAgi: [],
    uuidList: []
  }
}


export const initBilling = {
  billingStartDate: null,
  billingEndDate: null,
  billingCompany: null,
  aggregated_audio: 0,
  aggregated_ace: 0,
  aggregated_accessibility: 0,
  aggregated_dsm: 0,
  aggregated_mdiff: 0,
  aggregated_tc: 0,
  aggregated_agi: 0,
  billingProjectsAudio: [],
  billingProjectsAccessibility: [],
  billingProjectsACE: [],
  billingProjectsDSM: [],
  billingProjectsTC: [],
  billingProjectsMD: [],
  billingProjectsAGI: [],
};

const initialState = {
  page: "/",
  user: null,
  userEdit: null,
  userEditOrigin: null,
  userList: [],
  userGroup: {},
  userGroupEdit: {},
  userGroupList: [],
  company: {},
  companyEdit: {},
  companyList: [],
  project: {},
  projectEdit: {},
  projectList: [],
  tools: [],
  available_tools: [],
  accessToken: "",
  pending: false,
  error: null,
  companyId: "",
  authInfo: {},
  logList: [],
  usageLog: [],
  articlesList: [],
  articleEdit: null,
  currentArticle: null,
  message: null,
  projectApiKey: "",
  //BILLING
  ...initBilling,
  //CODER
  ...initCoder,
  //AGI
  ...initAGI,
};



// const init = (initialState, storageKey) => {
//   let preloadedState;
//   try {
//     preloadedState = JSON.parse(window.localStorage.getItem(storageKey));
//   } catch (e) {
//     console.log(e);
//   }
//   return preloadedState || initialState;
// };

// const useValue = () => {
//   const initialStateGen = init(initialState, storageKey);

//   const [state, dispatch] = useReducerAsync(
//     reducer,
//     initialStateGen,
//     asyncActionHandlers
//   );

//   useEffect(() => {
//     window.localStorage.setItem(storageKey, JSON.stringify(state));
//   }, [state]);

//   return [state, dispatch];
// };

const useValue = () => {
  return useReducerAsync(reducer, initialState, asyncActionHandlers);
};

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
