import { useState } from "react";
import { createContainer } from "react-tracked";

// Initial state of the app. Everything here is available in any component without props
// ADD_LANG
export const initLangs = {
  english: [],
  spanish: [],
  french: [],
  german: [],
  dutch: [],
  russian: [],
  italian: [],
  swedish: [],
  danish: [],
  norwegian: [],
  polish: [],
  romanian: [],
  finnish: [],
  portuguese: [],
  chinese: [],
  japanese: [],
};

const initialState = {
  userDB: null,
  project: {
    id: null,
    name: null,
    description: null,
    office: null,
    safeWords: [],
    data: [],
    dicts: initLangs,
    ndicts: initLangs,
  },
  page: "main",
  uuidList: [],
  openDraw: false,
  preSwitch: {
    binary_pred: true,
    swear: true,
    ndict: true,
    dict: true,
    fuzzy: true,
    dub: true,
  },
  extSwitch: {
    gibberish: true,
    short: false,
  },
  tablePage: 0,
  tableSort: [{id: "tf_pred", desc: true}]
};
 
const useMyState = () => useState(initialState);

export const {
  Provider: SharedStateProvider,
  useTracked: useSharedState,
} = createContainer(useMyState);
