import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import fuzz from "fuzzball";
import _ from "lodash";
import { saveAs } from "file-saver";

function setAPI(token) {

  return axios.create({
    baseURL: process.env.REACT_APP_SERVER,
    headers: { Authorization: `Bearer ${token}` },
  });
}

function setAPIUnrestricted() {
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER,
    //headers: { Authorization: `Bearer ${token}` },
  });
}

function setCoderAPI(token) {
  return axios.create({
    baseURL: process.env.REACT_APP_TC_SERVER,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const asyncActionHandlers = {
  NEW_USER:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/admin/", {
            email: action.email,
            company: action.company,
          });

          dispatch({ type: "SET_USER", user: data.data[0] });

          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  LOGIN:
    ({ dispatch, getState }) =>
      async () => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/admin/self_get", {});
          dispatch({ type: "SET_USER", user: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  USER_PAGE:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/admin/get", {
            id: action.user_id,
          });
          dispatch({ type: "SET_USER_EDIT", userEdit: data.data[0] });
          dispatch({
            type: "SET_USER_EDIT_ORIGIN",
            userEditOrigin: data.data[0],
          });
          dispatch({ type: "SET_PAGE", page: "/adm/profile" });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_USER:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/admin/put", {
            ...action.user,
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully edited user"
          })
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },

  CREATE_USER:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        alert(JSON.stringify(action.user));
        try {
          dispatch({ type: "STARTED" });
          await API.post("/admin/", {
            ...action.user,
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully added user"
          })
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },

  DEL_USER:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/admin/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  GET_USER_LIST:
    ({ dispatch, getState }) =>
      async () => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/admin/list", {});
          dispatch({ type: "SET_USER_LIST", userList: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },

  ADD_COMPANY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, companyEdit } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/company/", companyEdit);
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully added company"
          })
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_COMPANY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/company/get", {
            id: action.id,
          });
          dispatch({ type: "SET_COMPANY", company: data.data[0] });
          dispatch({ type: "SET_EDIT_COMPANY", company: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  LIST_COMPANY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/company/list", {});
          dispatch({ type: "SET_COMPANY_LIST", companyList: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_COMPANY:
    ({ dispatch, getState }) =>
      async () => {
        const { accessToken, companyEdit } = getState();
        const API = setAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          await API.post("/company/put", companyEdit);
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully edited company"
          })
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_COMPANY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/company/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, user } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/project/", {
            name: action.name,
            description: action.description,
            tool: action.tool,
            status: action.status,
            usergroups: action.usergroups,
            users: action.users,
            details: action.details,
          });
          const projectApiKey = await API.post("/api_key/", {
            tool: action.tool,
            generated_by: user.id,
            project: data.data[0].id,
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully added project"
          })
          dispatch({
            type: "SET_PROJECT",
            project: { ...data.data[0], apiKey: projectApiKey.data.data[0].key },
          });
          dispatch({
            type: "SET_PRJ_API_KEY",
            apiKey: projectApiKey.data.data[0].key,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  UPDATE_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, user } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/project/put", {
            id: action.id,
            name: action.name,
            description: action.description,
            id_hum: action.id_hum,
            tool: action.tool,
            status: action.status,
            creator: action.creator,
            company: action.company,
            usergroups: action.usergroups,
            users: action.users,
            details: action.details,
          });
          const projectApiKey = await API.post("/api_key/", {
            tool: action.tool,
            generated_by: user.id,
            project: data.data[0].id,
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully edited project"
          })
          dispatch({
            type: "SET_PROJECT",
            project: { ...data.data[0], apiKey: projectApiKey.data.data[0].key },   
          });
          dispatch({
            type: "SET_PRJ_API_KEY",
            apiKey: projectApiKey.data.data[0].key,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  FETCH_API_KEY: 
  ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          
          const projectApiKey = await API.post("/api_key/get", {
            id: action.id,
          });
          console.log(projectApiKey);
          dispatch({
            type: "SET_PRJ_API_KEY",
            apiKey: projectApiKey.data.data[0].key,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/project/get", {
            id: action.id,
          });
          const projectApiKey = await API.post("/api_key/get", {
            project: data.data[0].id,
          });
          dispatch({ type: "SET_PROJECT", project: data.data[0] });
          dispatch({
            type: "SET_PRJ_API_KEY",
            apiKey: projectApiKey.data.data[0].key,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/project/put", {
            id: action.id,
            name: action.name,
            description: action.description,
            id_hum: action.id_hum,
            tool: action.tool,
            status: action.status,
            creator: action.creator,
            company: action.company,
            usergroups: action.usergroups,
            users: action.users,
            details: action.details,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_PROJECT_LIST:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/project/list", {});
          dispatch({ type: "SET_PROJECTS", projectList: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          console.log(action.id)
          await API.post("/project/delete", {
            id: action.id,
          });
          const { data } = await API.post("/project/list", {});
          dispatch({ type: "SET_PROJECTS", projectList: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_USAGELOGS:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });

          const { data } = await API.post("/usage_log/list", {});
          dispatch({ type: "SET_USAGELOG", usagelog: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },

  ADD_USERGROUP:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const resp = await API.post("/usergroup/", {
            ...action.usergroup
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully created usergroup"
          })
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_USERGROUP:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/usergroup/get", {
            id: action.id,
          });
          dispatch({ type: "SET_USERGROUP", userGroup: data.data });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_USERGROUP:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/usergroup/put", {
            ...action.usergroup
          });
          dispatch({
            type: "SET_MESSAGE",
            message: "Successfully edited usergroup"
          })
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_USERGROUP:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/usergroup/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  LIST_USERGROUP:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/usergroup/list");
          dispatch({ type: "SET_LIST_USERGROUP", list: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_TOOL:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/tool/", {
            name: action.name,
            path: action.path,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_TOOL:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/tool/get", {
            id: action.id,
          });
          dispatch({ type: "SET_TOOL", tool: data.data });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_TOOL_LIST:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/tool/list_all", {});
          dispatch({ type: "SET_TOOLS", tools: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_AVAILABLE_TOOL_LIST:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/tool/list", {});
          dispatch({ type: "SET_AVAILABLE_TOOLS", tools: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_TOOL:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/tool/put", {
            id: action.id,
            name: action.name,
            path: action.path,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_TOOL:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/tool/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_USAGELOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/usage_log/", {
            tool: action.tool_id,
            user: action.user_id,
            log_entry: action.log_entry,
            project_id: action.project_id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_USAGELOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/usage_log/get", {
            id: action.id,
          });
          dispatch({ type: "SET_USAGELOG", tool: data.data });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  PUT_USAGELOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/usage_log/put", {
            id: action.id,
            tool: action.tool_id,
            user: action.user_id,
            log_entry: action.log_entry,
            project_id: action.project_id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_USAGELOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/usage_log/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_APIKEY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/api_key/", {
            tool: action.tool_id,
            generated_by: action.generated_by_id,
            project: action.project_id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_APIKEY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/api_key/get", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_APIKEY:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/api_key/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_COMPANYLOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/company_log/", {
            who: action.who_id,
            what: action.description,
            route: action.route,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  GET_COMPANYLOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/company_log/get", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  LIST_COMPANYLOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data } = await API.post("/company_log/list", {});
          dispatch({ type: "SET_COMPANYLOG_LIST", loglist: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  DEL_COMPANYLOG:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/company_log/delete", {
            id: action.id,
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  //TEXT CODER-->
  ADD_CODER_PROJECT_DETAILS:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setAPI(accessToken);
        const APICoder = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await APICoder.post("/details/", {
            verbatimId: "_" + uuidv4().replace(/-/g, ""),
            codebooks: [],
          });
          const { data: projectData } = await API.post("/project/", {
            name: action.name,
            description: action.description,
            tool: action.tool,
            status: action.status,
            usergroups: action.usergroups,
            users: action.users,
            details: detailsData.data[0].id,
            id: action.id,
          });

          dispatch({
            type: "SET_PROJECT",
            project: { ...projectData.data[0] },
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsData.data[0],
          });
          dispatch({
            type: "SET_PAGE",
            page: "/bright_tc/project",
          });

          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  UPDATE_CODER_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, user } = getState();
        const API = setAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: projectData } = await API.post("/project/put", {
            id: action.id,
            name: action.name,
            description: action.description,
            creator: user.id,
            company: user.company,
            status: action.status,
            tool: action.tool,
            details: action.details,
          });

          dispatch({
            type: "SET_PROJECT",
            project: { ...projectData.data[0] },
          });
          dispatch({
            type: "SET_PAGE",
            page: "/bright_tc/project",
          });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
  CHANGE_CODEBOOK_NAME:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });

          const { data: detailsDataUpdate } = await API.post("/details/put", {
            id: action.details_id,
            verbatimId: detailsData.data[0].verbatimId,
            codebooks: detailsData.data[0].codebooks.map((el) =>
              el.id === action.codebookTab
                ? {
                  id: el.id,
                  name: action.name,
                }
                : el
            ),
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsDataUpdate.data[0],
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_NEW_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });

          const { data: detailsDataUpdate } = await API.post("/details/put", {
            id: action.details_id,
            verbatimId: detailsData.data[0].verbatimId,
            codebooks: [
              ...detailsData.data[0].codebooks,
              {
                id: "_" + uuidv4().replace(/-/g, ""),
                name: action.name,
              },
            ],
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsDataUpdate.data[0],
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  EXPORT_FILE:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });

          const { data: detailsData } = await API.post(
            `/files/${action.filetype}`,
            {
              id: action.id,
              codebookId: action.codebook,
              duplicate: action.duplicate,
              stats: action.stats
            },
            { responseType: "blob" }
          );

          dispatch({
            type: "SET_EXPORT_FILE",
            file: detailsData,
            filename: action.filetype,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  SELECT_PROJECT:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsData.data[0],
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DELETE_CODEBOOK_TAB:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });

          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });
          const { data: detailsDataUpdate } = await API.post("/details/put", {
            id: action.details_id,
            verbatimId: detailsData.data[0].verbatimId,
            codebooks: detailsData.data[0].codebooks.filter(
              (el) => el.id !== action.id
            ),
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsDataUpdate.data[0],
          });

          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DUPLICATE_CODEBOOK:
    //duplicate setup is very prone to mistake by the user, might be good idea to become templates eventually
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, coderDetails: { verbatimId } } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });

          const { data: detailsDataUpdate } = await API.post("/details/put", {
            id: action.details_id,
            verbatimId: detailsData.data[0].verbatimId,
            codebooks: detailsData.data[0].codebooks.map((el) =>
              el.id === action.id
                ? {
                  id: el.id,
                  name: el.name,
                  duplicate: action.from_id,
                }
                : el
            ),
          });
          dispatch({
            type: "SET_CODER_DETAILS",
            coderDetails: detailsDataUpdate.data[0],
          });
          if (action.from_id === null) {
            await API.post("/codebook/clearverbatim", {
              id: action.id,
              verbatimId: verbatimId
            });
          }
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });

          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  CLONE_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/codebook/clone", {
            id: action.id,
            from_id: action.from_id,
          });
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  CLONE_DUP_DETAILS:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const { data: detailsData } = await API.post("/details/get", {
            id: action.details_id,
          });
          dispatch({
            type: "SET_CLONE_DUP_DETAILS",
            clonedupDetails: detailsData.data[0],
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DELETE_VERBATIM:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/verbatim/delete", {
            id: action.id,
          });
          const resp = await API.post("/verbatim/getinfo", {
            id: action.id,
          });
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: resp.data });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  APPEND_VERBATIM:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const formData = new FormData();
          formData.append("file", action.file);
          formData.append("id", action.id);
          const resp = await API.post("/upload/appendverbatim", formData);
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: resp.data });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  UPLOAD_VERBATIM:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const formData = new FormData();
          formData.append("file", action.file);
          formData.append("id", action.id);
          await API.post("/upload/verbatim", formData);
          const resp = await API.post("/verbatim/getinfo", {
            id: action.id,
          });
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: resp.data });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  GET_VERBATIM_INFO:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const resp = await API.post("/verbatim/getinfo", {
            id: action.id,
          });
          dispatch({
            type: "ADD_VERBATIM_INFO",
            verbatimInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  GET_CODER_DATA:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, codebookCoderId, verbatimCoderId, codebookDuplicateId } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const respCodebook = await API.post("/codebook/getdata", {
            id: codebookDuplicateId ? codebookDuplicateId : codebookCoderId,
          });
          const respData = await API.post("/verbatim/getdata", {
            id: verbatimCoderId,
            codebookId: codebookCoderId,
          });
          const respInfo = await API.post("/verbatim/getinfo", {
            id: verbatimCoderId,
          });

          const verbatimData = respData.data.data.map((el) => {
            const custom = _.pick(el, Object.keys(respData.data.filterColumns));
            return {
              ...el,
              sug: sugestion_loop(respCodebook.data, el.text_lower),
              custom: custom
            };
          });
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: respInfo.data });
          dispatch({
            type: "ADD_CODEBOOK_DATA",
            codebookData: respCodebook.data,
          });
          dispatch({ type: "ADD_VERBATIM_DATA", verbatimData: verbatimData });
          dispatch({
            type: "SET_FREQMAP",
            coderfreqMap: verbatimData.reduce((acc, curr) => {
              curr.code_id.forEach((id) => {
                acc[id] = (acc[id] || 0) + 1;
              });
              return acc;
            }, {}),
          });
          dispatch({
            type: "ADD_VERBATIM_FILTER_COLUMNS",
            verbatimFilterColumns: respData.data.filterColumns,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  GET_CODEBOOK_DATA:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const respData = await API.post("/codebook/getdata", {
            id: action.id,
          });
          dispatch({ type: "ADD_CODEBOOK_DATA", codebookData: respData.data });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  ASSIGN_VERBATIM_COLUMN:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          await API.post("/verbatim/updatecodebook", {
            id: action.id,
            codebookId: action.codebookId,
            labels: action.labels,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  SET_VERBATIM_CODE:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, verbatimData, project, company } = getState();
        const API = setCoderAPI(accessToken);
        try {
          var start = performance.now()
          dispatch({ type: "STARTED" });
          const coder_ids = _.keyBy(action.coder_ids, "id");
          let coder_ids_new = [];
          // there might be a more efficient way to do this
          const verbatimDataNew = verbatimData.map((el) => {
            if (el.id in coder_ids) {
              const newCodeId = coder_ids[el.id].code_id.length
                ? _.xor(el.code_id, coder_ids[el.id].code_id)
                : coder_ids[el.id].code_id;

              coder_ids_new = [
                ...coder_ids_new,
                { id: el.id, code_id: newCodeId },
              ];
              return { ...el, code_id: newCodeId };
            } else {
              return el;
            }
          });

          if (!action.history) {
            dispatch({
              type: "VERBATIM_HISTORY",
              coder_ids: coder_ids,
            });
          }
          dispatch({
            type: "ADD_VERBATIM_DATA_CODER",
            verbatimData: verbatimDataNew,
          });
          dispatch({
            type: "SET_FREQMAP",
            coderfreqMap: verbatimDataNew.reduce((acc, curr) => {
              curr.code_id.forEach((id) => {
                acc[id] = (acc[id] || 0) + 1;
              });
              return acc;
            }, {}),
          });
          var end = performance.now();
          console.log(`Setting the codes took ${end - start} ms`);
          await API.post("/verbatim/updatecode", {
            id: action.id,
            code_ids: coder_ids_new,
            project: { id: project["id"], name: project["name"] },
            company: { id: company["id"], name: company["name"] }
          });
          const resp = await API.post("/verbatim/getinfo", {
            id: action.id,
          });
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: resp.data });
          
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  SET_VERBATIM_LOCK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, verbatimData } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const locked_ids = _.keyBy(action.locked_ids, "id");
          let locked_ids_new = [];

          const verbatimDataNew = verbatimData.map((el) => {
            if (el.id in locked_ids) {
              const newLockedId = locked_ids[el.id].code_id.length
                ? _.xor(el?.locked, locked_ids[el.id].code_id)
                : locked_ids[el.id].locked || [];

              locked_ids_new = [
                ...locked_ids_new,
                { id: el.id, code_id: newLockedId },
              ];
              return { ...el, locked: newLockedId };
            } else {
              return el;
            }
          });

          dispatch({
            type: "ADD_VERBATIM_DATA_CODER",
            verbatimData: verbatimDataNew,
          });

          await API.post("/verbatim/updatelocked", {
            id: action.id,
            locked_ids: locked_ids_new,
          });

          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  EXECUTE_SENTIMENT:
  ({ dispatch, getState }) =>
      async (action) => {
        
        const { accessToken, verbatimSelected} = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/verbatim/execute_sentiment", {
            id: action.id,
            ids: action.ids,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DELETE_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken, coderDetails: { verbatimId } } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/codebook/delete", {
            id: action.id,
            verbatimId: verbatimId,
          });
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          const respVerb = await API.post("/verbatim/getinfo", {
            id: verbatimId,
          });
          dispatch({ type: "ADD_VERBATIM_INFO", verbatimInfo: respVerb.data });

          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  CREATE_EMPTY_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const resp = await API.post("/codebook/createempty", {
            id: action.id,
          });
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  APPEND_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const formData = new FormData();
          formData.append("file", action.file);
          formData.append("id", action.id);
          const resp = await API.post("/upload/appendcodebook", formData);
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  ADD_ROWS_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          await API.post("/codebook/addrows", {
            id: action.id,
            rows: action.rows,
          });
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          const respData = await API.post("/codebook/getdata", {
            id: action.id,
          });

          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "ADD_CODEBOOK_DATA", codebookData: respData.data });
          dispatch({ type: "VERBATIM_SUG_LOOP" });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  UPLOAD_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);

        try {
          dispatch({ type: "STARTED" });
          const formData = new FormData();
          formData.append("file", action.file);
          formData.append("id", action.id);
          await API.post("/upload/codebook", formData);
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  GET_CODEBOOK_INFO:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          const resp = await API.post("/codebook/getinfo", {
            id: action.id,
          });
          dispatch({
            type: "ADD_CODEBOOK_INFO",
            codebookInfo: resp.data,
          });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  EDIT_ROWS_CODEBOOK:
    ({ dispatch, getState }) =>
      async (action) => {
        const { accessToken } = getState();
        const API = setCoderAPI(accessToken);
        try {
          dispatch({ type: "STARTED" });
          console.log(action.rows);
          await API.post("/codebook/updaterows", {
            id: action.id,
            rows: action.rows,
          });
          const respData = await API.post("/codebook/getdata", {
            id: action.id,
          });
          dispatch({ type: "ADD_CODEBOOK_DATA", codebookData: respData.data });
          dispatch({ type: "VERBATIM_SUG_LOOP" });
          dispatch({ type: "END" });
        } catch (error) {
          dispatch({ type: "FAILED", error });
        }
      },
  DELETE_CODEBOOK_ROW: ({ dispatch, getState }) =>
    async (action) => {
      const { accessToken, verbatimCoderId, codebookCoderId, verbatimData } = getState();
      const API = setCoderAPI(accessToken);
      try {
        dispatch({ type: "STARTED" });
        console.log({
          id: codebookCoderId,
          verbatimId: verbatimCoderId,
          rows: action.rows,
        });
        await API.post("/codebook/deleterows", {
          id: codebookCoderId,
          verbatimId: verbatimCoderId,
          rows: action.rows,
        });
        const respData = await API.post("/codebook/getdata", {
          id: codebookCoderId,
        });

        const verbatimDataNew = verbatimData.map((el) => {

          const newCodeId = el.code_id.length
            ? el.code_id.filter(el_f => !action.rows.includes(el_f))
            : el.code_id;

          return { ...el, code_id: newCodeId };

        });

        dispatch({
          type: "ADD_VERBATIM_DATA_CODER",
          verbatimData: verbatimDataNew,
        });

        dispatch({
          type: "SET_FREQMAP",
          coderfreqMap: verbatimDataNew.reduce((acc, curr) => {
            curr.code_id.forEach((id) => {
              acc[id] = (acc[id] || 0) + 1;
            });
            return acc;
          }, {}),
        });


        dispatch({
          type: "ADD_CODEBOOK_DATA", codebookData: respData.data
        });
        dispatch({ type: "VERBATIM_SUG_LOOP" });
        dispatch({ type: "END" });
      } catch (error) {
        dispatch({ type: "FAILED", error });
      }
    },
  GET_ARTICLES:
    ({ dispatch, getState }) =>
      async (action) => {
        
        const API = setAPIUnrestricted();

        try {
          dispatch({ type: "STARTED" });

          const { data } = await API.post("/articles/list", {});
          dispatch({ type: "SET_ARTICLES", articles: data.data[0] });
          dispatch({ type: "END" });
        } catch (error) {
          console.log(error);
          dispatch({ type: "FAILED", error });
        }
      },
};

export function sugestion_loop(codebookData, text_verb) {
  let sug = [];
  const text_arr = text_verb.split(/[\s,]+/);
  console.log('in suggestion loop');
  for (const el of codebookData) {
    if (el.text.trim().toLowerCase() === text_verb) {
      sug.push(el.id);
    }
    if (el.reg && RegExp(el.reg).test(text_verb)) {
      sug.push(el.id);
    }
    if (fuzz.ratio(el.text, text_verb) > 70) {
      sug.push(el.id);
    }
  }
  for (const text of text_arr) {
    for (const el of codebookData) {
      if (el.text.trim().toLowerCase() === text) {
        sug.push(el.id);
      }
      if (el.reg && RegExp(el.reg).test(text)) {
        sug.push(el.id);
      }
      if (fuzz.ratio(el.text, text) > 70) {
        sug.push(el.id);
      }
    }
  }
  return [...new Set(sug)];
}
