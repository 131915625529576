import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

var secondaryFirebaseConfig = {
   apiKey: "AIzaSyAgDxBT_u-sDkkZ_LEG8XLgbUPr1wHd3fM",
   authDomain: "bright-platform-307214.firebaseapp.com",
   databaseURL: "https://bright-platform-307214-default-rtdb.europe-west1.firebasedatabase.app",
   projectId: "bright-platform-307214",
   storageBucket: "bright-platform-307214.appspot.com",
   messagingSenderId: "457057537269",
   appId: "1:457057537269:web:0ca60cd9908c1e467209bc",
   measurementId: "G-TR512NW2HW",
   name: "AUDIO"
  };
var sec_app =  firebase.initializeApp(secondaryFirebaseConfig, 'audio');

export default sec_app