import React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

import { useDispatch, useTrackedState } from "../system/store";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  
  const {currentArticle, articlesList} = useTrackedState();

  const dispatch = useDispatch();

  const onChangeArticle = (article_key) => {
    const article = articlesList.find(elem => elem.key == article_key);
    //console.log(JSON.stringify(article));

    if(article_key === 'help_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/help" })
    }
    if(article_key === 'tos_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/tos" })
    }
    if(article_key === 'privacy_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/privacy-policy" })
    }
    if(article_key === 'changelog_general'){
      dispatch({ type: "SET_CURRENT_ARTICLE", article: article})
      dispatch({ type: "SET_PAGE", page: "/changelog" })
    }
    
  }
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem button={true} component="a" onClick={()=>onChangeArticle('help_general')}>
                <ListItemText primary="Help" />
              </ListItem>
              <ListItem button={true} component="a" onClick={()=>onChangeArticle('tos_general')}>
                <ListItemText primary="Terms of Service" />
              </ListItem>
              <ListItem button={true} component="a" onClick={()=>onChangeArticle('privacy_general')}>
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem button={true} component="a" onClick={()=>onChangeArticle('changelog_general')}>
                <ListItemText primary="Changelog" />
              </ListItem>
              
 
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem button={true}>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Bright Platform`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
